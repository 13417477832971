const PAGINATION = Object.freeze({
    DEFAULT_PAGE_SIZE: 20,
    DEFAULT_PAGE_NO: 1,
    DEFAULT_SIZE_OPTIONS: [10, 20, 50, 100, 500]
});

const VIEW_SUBCATEGORY = Object.freeze({
    ALL: "ALL",
    ONLY_LEVEL_ONE: "ONLY_LEVEL_ONE",
    ONLY_LEVEL_THREE: "ONLY_LEVEL_THREE",
    ONLY_LEVEL_TWO: "ONLY_LEVEL_TWO",
});

const OPERATIONS = Object.freeze({
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE",
    VIEW: "VIEW"
})
const TOGGLE = Object.freeze({
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    TRUE: true,
    FALSE: false
});

const ADMINTYPE = Object.freeze({
    SUPERADMIN: "SUPERADMIN",
    SUBADMIN: "SUBADMIN"
})

const USER_TYPES = Object.freeze({
    BUYER: "BUYER",
    SELLER: "SELLER",
})

const MODULE_SLUG = Object.freeze({
    OPEN_FOR_ALL: "OPEN_FOR_ALL",
    ADMIN: "ADMIN",
    ROLE: "ROLE",
    USER: "USER",
    CATEGORY: "CATEGORY",
    BANK: "BANK",
    DESIGNATION: "DESIGNATION",
    BUSINESSTYPE: "BUSINESSTYPE",
    COLOR: "COLOR",
    PRODUCT: "PRODUCT",
    FAQ: "FAQ",
    POLICY: "POLICY",
    STATIC_PAGE: "STATIC_PAGE",
    BRAND: "BRAND",
    HSNCODE: "HSNCODE",
    CATEGORY_RETURN_POLICY: "CATEGORY_RETURN_POLICY",
    ESCALATE_YOUR_ISSUE: "ESCALATED_ISSUE",
    SHARE_FEEDBACK: "USER_FEEDBACK",
    TOP_DEALS: "TOP_DEALS",
    POPULAR_STYLE_CODES: "POPULAR_STYLE_CODES",
    OFFERS: "OFFERS",
    EXPLORE_STYLES: "EXPLORE_STYLES",
    PROMOTE_ADVERTISEMENTS: "PROMOTE_ADVERTISEMENTS",
    NEWS: "NEWS",
    ORDER_MANAGEMENT: "ORDER_MANAGEMENT",
    COUPON_CODES: "COUPON_CODES",
    QUOTE_GENERATION: "QUOTE_GENERATION",
    SALES_ORDER: "SALES_ORDER",
    BRAND_SECTIONS: "BRAND_SECTIONS",
});

const SIDEBAR_GROUPS = Object.freeze({
    ADMIN: {
        key: 'ADMIN_GROUP',
        label: 'Admin'
    },
    BUSINESS: {
        key: 'BUSINESS_GROUP',
        label: 'Business'
    },
    NEWS: {
        key: 'NEWS',
        label: 'News'
    },
    CONTENT_PAGES: {
        key: 'CONTENT_PAGES_GROUP',
        label: 'Content Pages'
    },
    SMASHBOARD: {
        key: 'SMASHBOARD_GROUP',
        label: 'Smashboard'
    },
    APP_CONTENT: {
        key: 'APP_CONTENT_GROUP',
        label: 'App Content'
    },
    CUSTOMER_SUPPORT: {
        key: 'CUSTOMER_SUPPORT_GROUP',
        label: 'Customer Support'
    },
    PRODUCT: {
        key: 'PRODUCT_GROUP',
        label: 'Product'
    },
    ORDER_MANAGEMENT: {
        key: 'ORDER_MANAGEMENT_GROUP',
        label: 'Order Management'
    },
    QUOTE_GENERATION: {
        key: 'QUOTE_GENERATION_GROUP',
        label: 'Manual Quotation'
    }
});

const PERMISSION_SLUG = Object.freeze({
    LIST: "LIST",
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE",
    VIEW: "VIEW"
});

const CATEGORY_SLUG = Object.freeze({
    YARN: "YARN",
    FABRIC: "FABRIC",
    FASHION: "FASHION",
    ACCESSORIES: "ACCESSORIES"
})

const USER_ADDRESS_SLUG = Object.freeze({
    HOME: "Home",
    WORK: "Work",
    FACTORY: "Factory",
    WAREHOUSE: "Warehouse",
    OTHER: "Other",
})

const KYC_DOCUMENT_SLUGS = Object.freeze({
    GST_DOCUMENT_SLUG: "GST_DOCUMENT",
    // AADHAR_CARD_DOCUMENT_SLUG: "AADHAR_CARD_DOCUMENT",
    UDYAM_AADHAR_DOCUMENT_SLUG: "UDYAM_AADHAR_DOCUMENT",
    UDYOG_AADHAR_DOCUMENT_SLUG: "UDYOG_AADHAR_DOCUMENT",
    COMPANY_PAN_DOCUMENT_SLUG: "COMPANY_PAN_DOCUMENT",
})

const IMAGE_TYPE = Object.freeze({
    JPEG: "jpeg",
    JPG: "jpg",
    PNG: "png",
    JPEG_2: "JPEG",
    JPG_2: "JPG",
    PNG_2: "PNG",
    PDF: "pdf",
    PDF_2: "PDF"
});
const AWS_S3_PREFIX = Object.freeze({
    POLICY: 'policy',
    FAQ: 'faq',
    STATIC_PAGE: 'staticpage',
    CATEGORY_RETURN_POLICY: 'categoryreturnpolicy'
})
const UPLOAD_PATH = Object.freeze({
    ROOT: "dukaandost/temp",
    CATEGORIES: "dukaandost/categories",
    CATEGORIES_ORIGINAL: "dukaandost/categories/original",
    CATEGORIES_MEDIUM: "dukaandost/categories/medium",
    CATEGORIES_SMALL: "dukaandost/categories/small",
    SUB_CATEGORIES: "dukaandost/subcategories",
    BANKS: "dukaandost/banks",
    BANKS_ORIGINAL: "dukaandost/banks/original",
    BANKS_SMALL: "dukaandost/banks/small",
    BANKS_MEDIUM: "dukaandost/banks/medium",
    POLICY: "dukaandost/policy",
    STATIC_PAGE: "dukaandost/staticpage",
    FAQ: "dukaandost/faq",
    STATIC_PAGE_IMAGE_CONTENT: "dukaandost/staticpage",
    CATEGORY_RETURN_POLICY: "dukaandost/categoryreturnpolicy",
    HSN: "dukaandost/hsncodes",
    TOP_DEALS: "dukaandost/categories/:categoryId/topdeals",
    POPULAR_STYLES: "dukaandost/categories/:categoryId/popularstylecodes",
    OFFERS: "dukaandost/categories/:categoryId/offers",
    EXPLORE_STYLES: 'dukaandost/categories/:categoryId/explorestyles',
    PRODUCT_LISTING_GUIDELINE: "dukaandost/productguidelines",
    PROMOTE_ADVERTISEMENT_WEB: "dukaandost/advertisements/web",
    PROMOTE_ADVERTISEMENT_MOBILE: "dukaandost/advertisements/mobile",
    COLOR: "dukaandost/color",
    E_WAY_BILL: "dukaandost/orders/ewaybill",
    LR_COPY: "dukaandost/orders/lrcopy",
    PACKAGING_LIST: "dukaandost/orders/packaginglist",
    BRAND_SECTION : "dukaandost/brandsections"
});

const APPROVAL_TYPES = {
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    NOT_STARTED: "NOT_STARTED"
};
const ADVERTISEMENT_STATUS = {
    ACTIVE: "ACTIVE",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    EXPIRED: "EXPIRED",
    APPROVED: "APPROVED"
}

const ADMIN_PRODUCT_APPROVAL_TYPES = {
    APPROVED: "APPROVED",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    QC_REJECTED: "QC_REJECTED"
}

const ESCALATION_OPTIONS = {
    RESOLVED: "RESOLVED",
    PENDING: "PENDING",
}

const KYC_DOCUMENT_TYPES = {
    IMAGE: "IMAGE",
    PDF: "PDF",
};

const BANNER_TYPES_FOR_ADMIN = {
    BRAND: "Brand",
    PRODUCTS: "Products",
    SELLER_STORE: "Seller Store",
}

const PRODUCT_ATTRIBUTE_INPUT_TYPES = {
    MATERIAL_COMPOSITION: "MATERIAL_COMPOSITION",
    /*     PRODUCT_FEATURES: "PRODUCT_FEATURES", */
    RADIO_BUTTON: "RADIO_BUTTON",
    CHECK_BOX: "CHECK_BOX",
    NUMBER_VALUE: "NUMBER_VALUE",
    NUMBER_PERCENTAGE: "NUMBER_PERCENTAGE",
    COLOR_PALETTE: "COLOR_PALETTE",
    DROP_DOWN: "DROP_DOWN",
    MULTILINE_TEXT: "MULTILINE_TEXT",
    TEXT_BOX: "TEXT_BOX",
    FILE_UPLOAD: "FILE_UPLOAD",
    UNIT: "UNIT",
    THREAD_COUNT: "THREAD_COUNT"
}

const SMACKBOARD_LIMIT_TO_ADD = {
    POPULAR_STYLE: 4,
    EXPLORE_STYLES: 9
}
const PRODUCT_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    ARCHIVED: "ARCHIVED",
    PENDING: "PENDING",
}

const BUCKET_PATH = process.env.REACT_APP_BUCKET_URL;
const API_PLATFORM_TYPE = {
    CMS: "CMS"
}

const SELLER_APPROVAL_TYPES = {
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    BLOCKED: "BLOCKED",
}

const ADVERTISEMENT_TYPES = {
    BANNER_ADVERTISEMENT: "BANNER_ADVERTISEMENT",
    PRODUCT_ADVERTISEMENT: "PRODUCT_ADVERTISEMENT"
}

const PRODUCT_PROMOTION_TYPES = {
    TRENDING_PRODUCTS: "TRENDING_PRODUCTS",
    SIMILAR_PRODUCTS: "SIMILAR_PRODUCTS",
    PRODUCT_GRID: "PRODUCT_GRID",
    RECOMMENDED_PRODUCTS: "RECOMMENDED_PRODUCTS"

}


const BANNER_PROMOTION_TYPES = {
    TOP_BANNER: "TOP_BANNER",
    COLLECTION_BANNER: "COLLECTION_BANNER",
    DEAL_OF_THE_DAY_BANNER: "DEAL_OF_THE_DAY_BANNER",
    POPULAR_BRANDS_BANNER: "POPULAR_BRANDS_BANNER"

}
const PRODUCT_CHANGED_BY = {
    ADIMIN: "ADMIN",
    SELLER: "SELLER",
    BOTH: "BOTH"
}

const ORDER_STATUS_TYPES = {
    // NOT_SHIPPED:"NOT_SHIPPED",
    // IN_TRANSIT:"IN_TRANSIT",
    DISPATCHED: "DISPATCHED",
    OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
    DELIVERED: "DELIVERED",
    RTO: "RTO",
    // PICKED_UP:"PICKED_UP",
    // RETURNED:"RETURNED"
}

const TRACKING_ORDER_STATUS = {
    // IN_TRANSIT: "IN_TRANSIT",
    NOT_SHIPPED:"NOT_SHIPPED",  //not in sales order might in future we can remove
    DISPATCHED: "DISPATCHED",
    OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
    DELIVERED: "DELIVERED",
    // RTO: "RTO",
    // PICKED_UP: "PICKED_UP",     {In future we will add  this two tracking status on our requirement}
    // RETURNED: "RETURNED",
}

const RAZORPAY_PAYMENT_STATUS = {
    AUTHORIZED: "authorized",
    CAPTURED: "captured",
    REFUNDED: "refunded",
    FAILED: "failed",
    PENDING: "pending",
}

const CATEGORY_TYPE = {
    YARN: "YARN",
    FABRIC: "FABRIC",
    FASHION: "FASHION",
    ACCESSORIES: "ACCESSORIES"
}

const OFFERS_STATUS_TYPES = {
    ACTIVE: "ACTIVE",
    UPCOMING: "UPCOMING",
    EXPIRED: "EXPIRED"
}

const OFFERS_AND_COUPON_TYPE = {
    DD_COINS: "DD_COINS",
    FREE_DELIVERY: "FREE_DELIVERY",
    FLAT_DISCOUNT: "FLAT_DISCOUNT",
    UPTO_DISCOUNT: 'UPTO_DISCOUNT'
}

const DELIVERY_TYPES = {
    TO_PAY: "TO_PAY",
    PAID: "PAID",
}

const MANUAL_ORDER_CREATION_PAYMENT_TERMS = {
    '100%_ADVANCE': "100%_ADVANCE",
    '100%_PAYMENT_AGAINST_DELIVERY_(COD)': '100%_PAYMENT_AGAINST_DELIVERY_(COD)',
    PAYMENT_ON_CREDIT: "PAYMENT_ON_CREDIT",
    SPLIT_PAYMENT: "SPLIT_PAYMENT"
}

const PAYMENT_MODE = {
    ONLINE: "ONLINE",
    OFFLINE: "OFFLINE",
    HISTORY: "HISTORY"
}

const OFFLINE_PAYMENT_TYPES = {
    CHEQUE: "CHEQUE",
    RTGS: "RTGS",
    IMPS: "IMPS",
    NEFT: "NEFT",
    UPI: "UPI",
    CASH: "CASH",
}

const BALANCE_TYPES = {
    CREDIT: "CREDIT",
    DEBIT: "DEBIT"
}

const ORDER_DOCUMENT_TYPES = {
    INVOICE: "INVOICE",
    E_WAY_BILL: "E_WAY_BILL",
    LR_COPY: "LR_COPY",
}

const SHIPMENT_MANAGEMENT_TYPE = {
    OFFLINE : "OFFLINE",
    DELHIVERY : "DELHIVERY"
}

module.exports = {
    ADVERTISEMENT_STATUS,
    DEVELOPMENT_ENV: "development",
    API_BASEURL: "https://reqres.in/api",

    BREADCRUMB_SEPARATOR: ">",

    LOGIN_DATA: {
        name: "eve holt",
        email: "admin@dukkandost.com",
        password: "Admin@123"
    },
    REDUX_LOCAL_STORE_KEY: 'dukan-dost-data',
    PERMISSION_SLUG,
    MODULE_SLUG,
    PAGINATION,
    IMAGE_TYPE,
    UPLOAD_PATH,
    OPERATIONS,
    TOGGLE,
    USER_TYPES,
    BUCKET_PATH,
    ADMINTYPE,
    AWS_S3_PREFIX,
    APPROVAL_TYPES,
    KYC_DOCUMENT_TYPES,
    KYC_DOCUMENT_SLUGS,
    PRODUCT_ATTRIBUTE_INPUT_TYPES,
    PRODUCT_STATUS,
    ADMIN_PRODUCT_APPROVAL_TYPES,
    ESCALATION_OPTIONS,
    SIDEBAR_GROUPS,
    SMACKBOARD_LIMIT_TO_ADD,
    VIEW_SUBCATEGORY,
    CATEGORY_SLUG,
    API_PLATFORM_TYPE,
    SELLER_APPROVAL_TYPES,
    BANNER_TYPES_FOR_ADMIN,
    ADVERTISEMENT_TYPES,
    PRODUCT_PROMOTION_TYPES,
    BANNER_PROMOTION_TYPES,
    PRODUCT_CHANGED_BY,
    ORDER_STATUS_TYPES,
    TRACKING_ORDER_STATUS,
    RAZORPAY_PAYMENT_STATUS,
    OFFERS_STATUS_TYPES,
    CATEGORY_TYPE,
    OFFERS_AND_COUPON_TYPE,
    DELIVERY_TYPES,
    USER_ADDRESS_SLUG,
    MANUAL_ORDER_CREATION_PAYMENT_TERMS,
    PAYMENT_MODE,
    OFFLINE_PAYMENT_TYPES,
    BALANCE_TYPES,
    ORDER_DOCUMENT_TYPES,
    SHIPMENT_MANAGEMENT_TYPE
}