import { MODULE_SLUG, SIDEBAR_GROUPS } from "../../config/constants";
import { LOCATIONS } from "../../config/routeConfig";
import Icon, {
    HomeOutlined,
    TeamOutlined,
    CarryOutOutlined,
    QuestionCircleOutlined,
    SafetyCertificateOutlined,
    ReadOutlined,
    ProfileOutlined,
    ShoppingOutlined,
    ScheduleOutlined,
    UserOutlined,
    BankOutlined,
    BgColorsOutlined,
    TagOutlined,
    FileTextOutlined,
    DropboxOutlined,
    RollbackOutlined,
    IssuesCloseOutlined,
    MessageOutlined,
    PercentageOutlined,
    AppstoreOutlined,
    HeatMapOutlined,
    TagsOutlined,
    FireOutlined,
    NotificationOutlined,
    DatabaseOutlined,
    DeliveredProcedureOutlined,
    WalletOutlined,
    GiftOutlined,
    FileDoneOutlined,
    ContainerOutlined,
} from '@ant-design/icons';

import { ReactComponent as SmashBoard } from '../../assets/svg/dashboard-tile-svgrepo-com.svg';
import { MODULE_TITLES } from "../../config/moduleConfig";

export const sidebarLocations = {
    [LOCATIONS.DASHBOARD_ROUTE.ROOT]: [ LOCATIONS.DASHBOARD_ROUTE.ROOT ],
    [LOCATIONS.ADMIN_ROUTES.ROOT]: [ SIDEBAR_GROUPS.ADMIN.key, LOCATIONS.ADMIN_ROUTES.ROOT ],
    [LOCATIONS.ROLE_ROUTES.ROOT]: [ SIDEBAR_GROUPS.ADMIN.key, LOCATIONS.ROLE_ROUTES.ROOT ],
    [LOCATIONS.USER_ROUTES.ROOT]: [ LOCATIONS.USER_ROUTES.ROOT ],
    [LOCATIONS.CATEGORY_ROUTES.ROOT]: [ LOCATIONS.CATEGORY_ROUTES.ROOT],
    [LOCATIONS.BRAND_ROUTES.ROOT]: [ LOCATIONS.BRAND_ROUTES.ROOT],
    [LOCATIONS.HSN_ROUTES.ROOT]: [ SIDEBAR_GROUPS.APP_CONTENT.key, LOCATIONS.HSN_ROUTES.ROOT],
    [LOCATIONS.PRODUCT_ROUTES.ROOT]: [ LOCATIONS.PRODUCT_ROUTES.ROOT],
    [LOCATIONS.COLOR_ROUTES.ROOT]: [ SIDEBAR_GROUPS.APP_CONTENT.key, LOCATIONS.COLOR_ROUTES.ROOT],
    [LOCATIONS.CATEGORY_RETURN_POLICY_ROUTES.ROOT]: [ SIDEBAR_GROUPS.APP_CONTENT.key, LOCATIONS.CATEGORY_RETURN_POLICY_ROUTES.ROOT],
    // [LOCATIONS.BANK_ROUTES.ROOT]: [ SIDEBAR_GROUPS.BUSINESS.key, LOCATIONS.BANK_ROUTES.ROOT],
    [LOCATIONS.DESIGNATION_ROUTES.ROOT]: [ SIDEBAR_GROUPS.BUSINESS.key, LOCATIONS.DESIGNATION_ROUTES.ROOT],
    [LOCATIONS.NEWS.ROOT]: [ SIDEBAR_GROUPS.NEWS.key, LOCATIONS.NEWS.ROOT],
    [LOCATIONS.BUSINESSTYPE_ROUTES.ROOT]: [ SIDEBAR_GROUPS.BUSINESS.key,LOCATIONS.BUSINESSTYPE_ROUTES.ROOT],
    [LOCATIONS.SHARE_FEEDBACK_ROUTES.ROOT]: [ SIDEBAR_GROUPS.CUSTOMER_SUPPORT.key, LOCATIONS.SHARE_FEEDBACK_ROUTES.ROOT],
    [LOCATIONS.ESCALATE_YOUR_ISSUE.ROOT]: [ SIDEBAR_GROUPS.CUSTOMER_SUPPORT.key,LOCATIONS.ESCALATE_YOUR_ISSUE.ROOT],
    [LOCATIONS.FAQ_ROUTES.FAQ_TYPE.ROOT]: [ SIDEBAR_GROUPS.CONTENT_PAGES.key, LOCATIONS.FAQ_ROUTES.FAQ_TYPE.ROOT],
    [LOCATIONS.FAQ_ROUTES.ROOT]: [ SIDEBAR_GROUPS.CONTENT_PAGES.key, LOCATIONS.FAQ_ROUTES.FAQ_TYPE.ROOT],
    [LOCATIONS.POLICY_ROUTES.ROOT]: [ SIDEBAR_GROUPS.CONTENT_PAGES.key, LOCATIONS.POLICY_ROUTES.ROOT],
    [LOCATIONS.STATIC_PAGES_ROUTES.ROOT]: [ SIDEBAR_GROUPS.CONTENT_PAGES.key, LOCATIONS.STATIC_PAGES_ROUTES.ROOT],
    [LOCATIONS.TOP_DEAL_ROUTES.ROOT]: [SIDEBAR_GROUPS.SMASHBOARD.key, LOCATIONS.TOP_DEAL_ROUTES.ROOT],
    [LOCATIONS.POPULAR_STYLE_CODES_ROUTES.ROOT]: [SIDEBAR_GROUPS.SMASHBOARD.key, LOCATIONS.POPULAR_STYLE_CODES_ROUTES.ROOT],
    [LOCATIONS.BRAND_SECTIONS_ROUTES.ROOT]: [SIDEBAR_GROUPS.SMASHBOARD.key,LOCATIONS.BRAND_SECTIONS_ROUTES.ROOT],
    [LOCATIONS.OFFERS_ROUTES.ROOT]: [SIDEBAR_GROUPS.SMASHBOARD.key, LOCATIONS.OFFERS_ROUTES.ROOT],
    [LOCATIONS.EXPLORE_STYLES.ROOT]: [SIDEBAR_GROUPS.SMASHBOARD.key, LOCATIONS.EXPLORE_STYLES.ROOT],
    [LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT]: [LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT],
    [LOCATIONS.ORDERS_AND_RETURN_ROUTES.ROOT] : [LOCATIONS.ORDERS_AND_RETURN_ROUTES.ROOT], 
    // QUOTE_GENERATION
    [LOCATIONS.QUOTE_GENERATION_ROUTES.ROOT]:[LOCATIONS.QUOTE_GENERATION_ROUTES.ROOT],
    [LOCATIONS.SALES_ORDER_ROUTES.ROOT]: [LOCATIONS.SALES_ORDER_ROUTES.ROOT]

}

export const side_menu_items = [
  {
    key: LOCATIONS.DASHBOARD_ROUTE.ROOT,
    icon: <HomeOutlined />,
    label: MODULE_TITLES.DASHBOARD.ROOT,
    module: MODULE_SLUG.OPEN_FOR_ALL,
  },
  {
    key: SIDEBAR_GROUPS.ADMIN.key,
    label: SIDEBAR_GROUPS.ADMIN.label,
    icon: <TeamOutlined />,
    children: [
      {
        key: LOCATIONS.ADMIN_ROUTES.ROOT,
        icon: <TeamOutlined />,
        label: MODULE_TITLES.ADMIN.ROOT,
        module: MODULE_SLUG.ADMIN,
      },
      {
        key: LOCATIONS.ROLE_ROUTES.ROOT,
        icon: <ScheduleOutlined />,
        label: MODULE_TITLES.ROLE.ROOT,
        module: MODULE_SLUG.ROLE,
      },
    ],
    modules: [MODULE_SLUG.ADMIN, MODULE_SLUG.ROLE],
  },
  {
    icon: <UserOutlined />,
    key: LOCATIONS.USER_ROUTES.ROOT,
    label: MODULE_TITLES.USER.ROOT,
    module: MODULE_SLUG.USER,
  },
  {
    key: LOCATIONS.CATEGORY_ROUTES.ROOT,
    icon: <CarryOutOutlined />,
    label: MODULE_TITLES.CATEGORY.ROOT,
    module: MODULE_SLUG.CATEGORY,
  },
  {
    key: SIDEBAR_GROUPS.PRODUCT.key,
    label: SIDEBAR_GROUPS.PRODUCT.label,
    icon: <DropboxOutlined />,
    children: [
      {
        key: LOCATIONS.PRODUCT_ROUTES.ROOT,
        icon: <DropboxOutlined />,
        label: MODULE_TITLES.PRODUCT.LIST,
        module: MODULE_SLUG.PRODUCT,
      },
      {
        key: LOCATIONS.BULK_LIST_ROUTES.ROOT,
        icon: <DatabaseOutlined />,
        label: MODULE_TITLES.BULK_LIST.ROOT,
        module: MODULE_SLUG.PRODUCT,
      },
    ],
    modules: [MODULE_SLUG.PRODUCT],
  },
  // {
  //     key: LOCATIONS.PRODUCT_ROUTES.ROOT,
  //     icon: <DropboxOutlined />,
  //     label: MODULE_TITLES.PRODUCT.LIST,
  //     module: MODULE_SLUG.PRODUCT
  // },
  {
    key: LOCATIONS.BRAND_ROUTES.ROOT,
    icon: <TagOutlined />,
    label: MODULE_TITLES.BRANDS.ROOT,
    module: MODULE_SLUG.BRAND,
  },
  {
    key: SIDEBAR_GROUPS.SMASHBOARD.key,
    label: SIDEBAR_GROUPS.SMASHBOARD.label,
    icon: <Icon component={SmashBoard} />,
    modules: [
      MODULE_SLUG.TOP_DEALS,
      MODULE_SLUG.POPULAR_STYLE_CODES,
      MODULE_SLUG.OFFERS,
      MODULE_SLUG.EXPLORE_STYLES,
    ],
    children: [
      {
        key: LOCATIONS.TOP_DEAL_ROUTES.ROOT,
        icon: <TagsOutlined />,
        label: MODULE_TITLES.TOP_DEAL.ROOT,
        module: MODULE_SLUG.TOP_DEALS,
      },
      {
        key: LOCATIONS.POPULAR_STYLE_CODES_ROUTES.ROOT,
        icon: <FireOutlined />,
        label: MODULE_TITLES.POPULAR_STYLES.ROOT,
        module: MODULE_SLUG.POPULAR_STYLE_CODES,
      },
      {
        key: LOCATIONS.OFFERS_ROUTES.ROOT,
        icon: <PercentageOutlined />,
        label: MODULE_TITLES.OFFERS.ROOT,
        module: MODULE_SLUG.OFFERS,
      },
      {
        key: LOCATIONS.EXPLORE_STYLES.ROOT,
        icon: <AppstoreOutlined />,
        label: MODULE_TITLES.EXPLORE_STYLES.ROOT,
        module: MODULE_SLUG.EXPLORE_STYLES,
      },
      {
        key: LOCATIONS.BRAND_SECTIONS_ROUTES.ROOT,
        icon: <HeatMapOutlined />,
        label: MODULE_TITLES.BRAND_SECTIONS.ROOT,
        module: MODULE_SLUG.OPEN_FOR_ALL,
      },
    ],
  },
  {
    key: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT,
    icon: <NotificationOutlined />,
    label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT,
    module: MODULE_SLUG.PROMOTE_ADVERTISEMENTS,
  },
  {
    key: SIDEBAR_GROUPS.ORDER_MANAGEMENT.key,
    label: SIDEBAR_GROUPS.ORDER_MANAGEMENT.label,
    icon: <DeliveredProcedureOutlined />,
    modules: [MODULE_SLUG.ORDER_MANAGEMENT],
    children: [
      {
        key: LOCATIONS.ORDERS_AND_RETURN_ROUTES.ROOT,
        icon: <WalletOutlined />,
        label: MODULE_TITLES.ORDER_AND_RETURNS.VIEW,
        module: [MODULE_SLUG.ORDER_MANAGEMENT],
      },
      // {
      //     key: LOCATIONS.ORDERS_AND_RETURN_ROUTES.ROOT,
      //     icon: <DeliveredProcedureOutlined />,
      //     label: MODULE_TITLES.ORDER_AND_RETURNS.ROOT,
      //     module: [MODULE_SLUG.ORDER_MANAGEMENT],
      // }
    ],
  },
  {
    key: LOCATIONS.OFFERS_AND_DISCOUNT_ROUTES.ROOT,
    icon: <GiftOutlined />,
    label: MODULE_TITLES.OFFERS_DISCOUNT.ROOT,
    module: MODULE_SLUG.COUPON_CODES,
  },
  {
    key: SIDEBAR_GROUPS.QUOTE_GENERATION.key,
    label: SIDEBAR_GROUPS.QUOTE_GENERATION.label,
    icon: <FileDoneOutlined />,
    children: [
      {
        key: LOCATIONS.QUOTE_GENERATION_ROUTES.ROOT,
        icon: <FileDoneOutlined />,
        label: MODULE_TITLES.QUOTE_GENERATION_NAME.ROOT,
        module: MODULE_SLUG.OPEN_FOR_ALL,
      },
      {
        key: LOCATIONS.SALES_ORDER_ROUTES.ROOT,
        icon: <ContainerOutlined />,
        label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER,
        module: MODULE_SLUG.OPEN_FOR_ALL,
      },
    ],
    modules: [ MODULE_SLUG.OPEN_FOR_ALL,],
  },
  {
    key: LOCATIONS.NEWS.ROOT,
    icon: <ReadOutlined />,
    label: MODULE_TITLES.NEWS.ROOT,
    module: MODULE_SLUG.NEWS,
  },
  {
    key: SIDEBAR_GROUPS.BUSINESS.key,
    label: SIDEBAR_GROUPS.BUSINESS.label,
    icon: <ShoppingOutlined />,
    modules: [
      MODULE_SLUG.BANK,
      MODULE_SLUG.DESIGNATION,
      MODULE_SLUG.BUSINESSTYPE,
    ],
    children: [
      // {
      //     key: LOCATIONS.BANK_ROUTES.ROOT,
      //     icon: <BankOutlined />,
      //     label: MODULE_TITLES.BANK.ROOT,
      //     module: MODULE_SLUG.BANK
      // },
      {
        key: LOCATIONS.DESIGNATION_ROUTES.ROOT,
        icon: <ProfileOutlined />,
        label: MODULE_TITLES.DESIGNATION.ROOT,
        module: MODULE_SLUG.DESIGNATION,
      },
      {
        key: LOCATIONS.BUSINESSTYPE_ROUTES.ROOT,
        icon: <ShoppingOutlined />,
        label: MODULE_TITLES.BUSINESS_TYPE.ROOT,
        module: MODULE_SLUG.BUSINESSTYPE,
      },
    ],
  },
  {
    key: SIDEBAR_GROUPS.APP_CONTENT.key,
    label: SIDEBAR_GROUPS.APP_CONTENT.label,
    icon: <ReadOutlined />,
    modules: [
      MODULE_SLUG.CATEGORY_RETURN_POLICY,
      MODULE_SLUG.HSNCODE,
      MODULE_SLUG.COLOR,
    ],
    children: [
      {
        key: LOCATIONS.CATEGORY_RETURN_POLICY_ROUTES.ROOT,
        icon: <RollbackOutlined />,
        label: MODULE_TITLES.CATEGORY_RETURN_POLICY.ROOT,
        module: MODULE_SLUG.CATEGORY_RETURN_POLICY,
      },
      {
        key: LOCATIONS.HSN_ROUTES.ROOT,
        icon: <FileTextOutlined />,
        label: MODULE_TITLES.HSN.ROOT,
        module: MODULE_SLUG.HSNCODE,
      },
      {
        key: LOCATIONS.COLOR_ROUTES.ROOT,
        icon: <BgColorsOutlined />,
        label: MODULE_TITLES.COLOR.ROOT,
        module: MODULE_SLUG.COLOR,
      },
    ],
  },
  {
    key: SIDEBAR_GROUPS.CUSTOMER_SUPPORT.key,
    label: SIDEBAR_GROUPS.CUSTOMER_SUPPORT.label,
    icon: <IssuesCloseOutlined />,
    modules: [MODULE_SLUG.ESCALATE_YOUR_ISSUE, MODULE_SLUG.SHARE_FEEDBACK],
    children: [
      {
        key: LOCATIONS.ESCALATE_YOUR_ISSUE.ROOT,
        icon: <IssuesCloseOutlined />,
        label: MODULE_TITLES.ESCALATE_YOUR_ISSUE.ROOT,
        module: MODULE_SLUG.ESCALATE_YOUR_ISSUE,
      },
      {
        key: LOCATIONS.SHARE_FEEDBACK_ROUTES.ROOT,
        icon: <MessageOutlined />,
        label: MODULE_TITLES.SHARE_FEEDBACK.ROOT,
        module: MODULE_SLUG.SHARE_FEEDBACK,
      },
    ],
  },
  {
    key: SIDEBAR_GROUPS.CONTENT_PAGES.key,
    label: SIDEBAR_GROUPS.CONTENT_PAGES.label,
    icon: <ReadOutlined />,
    modules: [MODULE_SLUG.FAQ, MODULE_SLUG.POLICY, MODULE_SLUG.STATIC_PAGE],
    children: [
      {
        key: LOCATIONS.FAQ_ROUTES.FAQ_TYPE.ROOT,
        icon: <QuestionCircleOutlined />,
        label: MODULE_TITLES.FAQ.FAQ_LIST.ROOT,
        module: MODULE_SLUG.FAQ,
      },
      {
        key: LOCATIONS.POLICY_ROUTES.ROOT,
        icon: <SafetyCertificateOutlined />,
        label: MODULE_TITLES.POLICY.ROOT,
        module: MODULE_SLUG.POLICY,
      },
      {
        key: LOCATIONS.STATIC_PAGES_ROUTES.ROOT,
        icon: <ReadOutlined />,
        label: MODULE_TITLES.STATIC_PAGE.ROOT,
        module: MODULE_SLUG.STATIC_PAGE,
      },
    ],
  },
];

/*
const side_menu_items = [
    {
        key: LOCATIONS.ADMIN_ROUTES.ROOT,
        icon: <TeamOutlined />,
        label: MODULE_TITLES.ADMIN.ROOT,
        module: MODULE_SLUG.ADMIN
    },
    {
        key: LOCATIONS.USER_ROUTES.ROOT,
        icon: <UserOutlined />,
        label: MODULE_TITLES.USER.ROOT,
        module: MODULE_SLUG.USER
    },
    {
        key: LOCATIONS.ROLE_ROUTES.ROOT,
        icon: <ScheduleOutlined />,
        label: MODULE_TITLES.ROLE.ROOT,
        module: MODULE_SLUG.ROLE
    },
    {
        key: LOCATIONS.CATEGORY_ROUTES.ROOT,
        icon: <CarryOutOutlined />,
        label: MODULE_TITLES.CATEGORY.ROOT,
        module: MODULE_SLUG.CATEGORY
    },
    {
        key: LOCATIONS.BRAND_ROUTES.ROOT,
        icon: <TagOutlined />,
        label: MODULE_TITLES.BRANDS.ROOT,
        module: MODULE_SLUG.BRAND
    },
    {
        key: LOCATIONS.HSN_ROUTES.ROOT,
        icon: <FileTextOutlined/>,
        label: MODULE_TITLES.HSN.ROOT,
        module: MODULE_SLUG.HSNCODE
    },
    {
        key: LOCATIONS.PRODUCT_ROUTES.ROOT,
        icon: <DropboxOutlined />,
        label: MODULE_TITLES.PRODUCT.LIST,
        module: MODULE_SLUG.PRODUCT
    },
    {
        key: LOCATIONS.CATEGORY_RETURN_POLICY_ROUTES.ROOT,
        icon: <RollbackOutlined />,
        label: MODULE_TITLES.CATEGORY_RETURN_POLICY.ROOT,
        module: MODULE_SLUG.CATEGORY_RETURN_POLICY
    },
    {
        key: LOCATIONS.BANK_ROUTES.ROOT,
        icon: <BankOutlined />,
        label: MODULE_TITLES.BANK.ROOT,
        module: MODULE_SLUG.BANK
    },
    {
        key: LOCATIONS.DESIGNATION_ROUTES.ROOT,
        icon: <ProfileOutlined />,
        label: MODULE_TITLES.DESIGNATION.ROOT,
        module: MODULE_SLUG.DESIGNATION
    },
    {
        key: LOCATIONS.BUSINESSTYPE_ROUTES.ROOT,
        icon: <ShoppingOutlined />,
        label: MODULE_TITLES.BUSINESS_TYPE.ROOT,
        module: MODULE_SLUG.BUSINESSTYPE
    },
    {
        key: LOCATIONS.COLOR_ROUTES.ROOT,
        icon: <BgColorsOutlined />,
        label: MODULE_TITLES.COLOR.ROOT,
        module: MODULE_SLUG.COLOR
    },
    {
        key: LOCATIONS.ESCALATE_YOUR_ISSUE.ROOT,
        icon: <IssuesCloseOutlined />,
        label: MODULE_TITLES.ESCALATE_YOUR_ISSUE.ROOT,
        module: MODULE_SLUG.ESCALATE_YOUR_ISSUE
    },
    {
        key: LOCATIONS.SHARE_FEEDBACK_ROUTES.ROOT,
        icon: <MessageOutlined />,
        label: MODULE_TITLES.SHARE_FEEDBACK.ROOT,
        module: MODULE_SLUG.SHARE_FEEDBACK
    },
    {
        key: LOCATIONS.FAQ_ROUTES.FAQ_TYPE.ROOT,
        icon: <QuestionCircleOutlined />,
        label: MODULE_TITLES.FAQ.FAQ_LIST.ROOT,
        module: MODULE_SLUG.FAQ
    },
    {
        key: LOCATIONS.POLICY_ROUTES.ROOT,
        icon: <SafetyCertificateOutlined />,
        label: MODULE_TITLES.POLICY.ROOT,
        module: MODULE_SLUG.POLICY
    },
    {
        key: LOCATIONS.STATIC_PAGES_ROUTES.ROOT,
        icon: <ReadOutlined />,
        label: MODULE_TITLES.STATIC_PAGE.ROOT,
        module: MODULE_SLUG.STATIC_PAGE
    },
];
*/