const COMMON = {
    SAVE: "Save",
    UPLOAD: "Upload",
    LOGOUT: "Logout",
    SETTING:"Settings"
}

const HSN = {
    ROOT: "HSN"
}

const ADMIN = {
    ROOT: "Admin",
    LIST: "Admin List",
    ADD:  "Add Admin",
    EDIT: "Edit Admin"
}

const BANK = {
    ROOT: "Bank",
    LIST: "Bank List",
    ADD:  "Add Bank",
    EDIT: "Edit Bank",
    VIEW: "View Bank"
}

const BUSINESS_TYPE = {
    ROOT: "Business Type",
    LIST: "Business Type List",
    ADD: "Add Business Type",
    EDIT: "Edit Business Type"
}

const CATEGORY = {
    ROOT: "Category",
    LIST: "Category List",
    ADD: "Add Category",
    EDIT: "Edit Category",
    VIEW: "View Category",
    SUBCATEGORY: {
        PRODUCT_NAME:{
            ROOT:"Product Name"
        },
        GUIDELINES:{
            MODAL: "Manage Product Guidelines"
        },
        SEO: {
            MODAL: "Manage SEO Content",
            TITLE: "SEO Content"
        },
        ROOT: "Sub Category",
        ADD: "Add Sub Category",
        MANAGE: "Manage Sub Category",
        EDIT:"Edit Sub Category"
    },
    ATTRIBUTE: {
        ROOT:"Attribute",
        ADD: "Add Attribute",
        EDIT: "Edit Attribute",
        LIST: "Attribute List"
    }
}

const CATEGORY_RETURN_POLICY = {
    ROOT:"Category Return Policy",
    EDIT: "Edit Category Return Policy",
    ADD: "ADD Category Return Policy",
    VIEW: "View Category Return Policy",
    LIST: "Category Return Policy List"
} 

const COLOR = {
    ROOT: "Color",
    LIST: "Color List",
    ADD: "Add Color",
    EDIT: "Edit Color"
};

const DASHBOARD = {
    ROOT: "Dashboard",
};

const DESIGNATION = {
    ROOT: "Designation",
    LIST: "Designation List",
    ADD: "Add Designation",
    EDIT: "Edit Designation"
}

const FAQ = {
    ROOT: "FAQ Type",
    LIST: "FAQ Type List",
    ADD: "Add FAQ Type",
    EDIT: "Edit FAQ Type",
    FAQ_LIST: {
        ROOT: "FAQ",
        LIST: "FAQ List",
        ADD: "Add FAQ",
        EDIT: "Edit FAQ",
        VIEW: "View FAQ"
    }
}

const POLICY = {
    ROOT: "Policy",
    LIST: "Policy List",
    ADD: "Add Policy",
    EDIT: "Edit Policy",
    VIEW: "View Policy"
}

const PROFILE = {
    ROOT: "Profile",
    EDIT: "Edit Profile"
}

const ROLE = {
    ROOT: "Role",
    LIST: "Role List",
    ADD: "Add Role",
    EDIT: "Edit Role"
}

const STATIC_PAGE = {
    ROOT: "Static Page",
    LIST: "Static Page List",
    ADD: "Add Static Page",
    EDIT: "Edit Static Page",
    VIEW: "View Static Page"
}

const USER = {
    ROOT: "User",
    LIST: "User List",
    VIEW: "User Details",
    REJECT_REASON: "Reason For Rejection",
    ADDRESS_LIST: "Address List",
    SELLER_COMMISSION: "Seller Commission List",
    MANAGE_PAYMENT:'Manage Payment',
};

const CATEGORY_TYPE_NAME = {
    FASHION: "Fashion",
    ACCESSORIES: "Accessories",
    YARN: "Yarn",
    FABRIC: "Fabric"
}

const BRANDS = {
    LIST: "Brands",
    ROOT: "Brand",
    SELLER_FOR_BRAND:{
      ROOT: "Sellers for brand ",
      LIST: "Seller List for brand"
    }
}

const PRODUCT = {
    LIST: "Product",
    VIEW: "Product Detail"
}

const BULK_LIST={
ROOT:"Bulk Product",
LIST:"Bulk Product Detail "
} 

const ESCALATE_YOUR_ISSUE = {
    ROOT: "Escalated Issues",
    LIST: "Escalated Issue List",
    VIEW_DIALOG: "Issue Details"
}
const SHARE_FEEDBACK = {
    ROOT: "User Feedbacks",
    LIST: "User Feedback List",
    VIEW_DIALOG: "User Feedback Details"
}
const SIZE = {
    ROOT: "Size",
    LIST: "Size List",
    ADD:  "Add Size List",
    EDIT: "Edit Size List"
}
const TOP_DEAL ={
    ROOT: "Top Deals",
    LIST: "Top Deal List",
    ADD: "Add Top Deal",
    EDIT:"Edit Top Deal"
}

const POPULAR_STYLES = {
    ROOT: 'Popular Styles',
    LIST: 'Popular Style List',
    ADD:  'Add Popular Style',
    EDIT: 'Edit Popular Style'
}

const OFFERS = {
    ROOT: 'Offers',
    LIST: 'Offer List',
    ADD:  'Add Offer',
    EDIT: 'Edit Offer'
}

const EXPLORE_STYLES = {
    ROOT: 'Explore Styles',
    LIST: 'Explore Styles List',
    ADD:  'Add Explore Style',
    EDIT: 'Edit Explore Style'
}

const BRAND_SECTIONS = {
    ROOT : 'Brand Section',
    LIST: 'Brand Section List',
    ADD:  'Add Brand Section',
    EDIT: 'Edit Brand Section'
}

const NEWS = {
    ROOT: 'News',
    LIST: 'News RSS Feed',
    ADD: "Add RSS Feed URL",
    EDIT: "Edit News URL"
}

const PROMOTE_ADVERTISEMENT = {
    ROOT: 'Advertisement',
    LIST: 'Advertisement Plan  List',
    ADD:  'Add Promote Advertisement',
    EDIT: 'Edit Promote Advertisement',
    VIEW: 'View Advertisement',
    VIEWBANNER: 'View Adv Detail'
}

const ORDER_AND_RETURNS = {
    ROOT:'Order Management',
    VIEW: 'View Orders'
}

const OFFERS_DISCOUNT={
    ROOT:'Coupon',
    LIST:'Coupon List',
    ADD: 'Add Coupon' ,
    VIEW:"Coupon Detail"
}
const SETTINGS = {
    ROOT:"General Settings"
}

const QUOTE_GENERATION_NAME={
    ROOT:'Quotation Generator',
    CREATE:'Create Quotation',
    EDIT:'Edit Quotation',
    ADD_PRODUCT_QUOTATION:'Add Product Quotation',
    ADDCHARGESFORQUOTATION:'Add Charges For Quotation',
    ADDNEWAADDRESS:'Add New Shipping Address',
    ADDNEWBILLINGADDRESS:'Add New Billing Address',
    SELECT_PRODUCT:'Select Product',
    SALES_ORDER:'Sales Order',
    SALES_ORDER_DETAIL:'Sales Order Detail',
    PROCESS_SALES_ORDER:'Process Sales Order',
    SUB_ORDER_DETAIL:'Sub Order Detail',
    MANAGE_PAYMENT:'Manage Payment',
    ONLINE_PAYMENT:"Online Payment",
    OFLINE_PAYMENT:"Ofline Payment",
    PAYMENT_HISTORY:"Payment History"
}

export const MODULE_TITLES = Object.freeze({
    COMMON,
    ADMIN,
    BANK,
    BUSINESS_TYPE,
    CATEGORY,
    COLOR,
    DASHBOARD,
    DESIGNATION,
    FAQ,
    POLICY,
    PROFILE,
    ROLE,
    STATIC_PAGE,
    USER,
    CATEGORY_TYPE_NAME,
    BRANDS,
    PRODUCT,
    HSN,
    CATEGORY_RETURN_POLICY,
    ESCALATE_YOUR_ISSUE,
    SHARE_FEEDBACK,
    SIZE,
    TOP_DEAL,
    POPULAR_STYLES,
    OFFERS,
    EXPLORE_STYLES,
    BRAND_SECTIONS,
    PROMOTE_ADVERTISEMENT,
    BULK_LIST,
    ORDER_AND_RETURNS,
    OFFERS_DISCOUNT,
    SETTINGS,
    QUOTE_GENERATION_NAME,
    NEWS
});
